import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Icons = ({icon, text, subText, link, size, width}) => {
    return(
        <a className="icons-a" href={link} target='_blank'>
            <div className="icon-container">
                <FontAwesomeIcon className='icon' icon={icon} color="#02639b" fontSize={size} width={width}/>
                <div className="icon-texts">
                    <div className="icon-text">{text}</div>
                    <div className="icon-subtext">{subText}</div>
                </div>
            </div>
        </a>
    )
}

export default Icons