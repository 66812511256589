import {Routes, Route} from 'react-router-dom'

import Home from './pages/Home.js'

const AppRouter = () => {
    return(
        <main>
            <Routes>
                <Route exact path="/" element={<Home />}/>
                <Route path="/servicos" element={<Home />}/>
            </Routes>
        </main>
    )
}

export default AppRouter;