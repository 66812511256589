import Button from "./Button"
const Slide = ({img, tittle, text, buttonOneProps, buttonTwoProps}) => {
    return(
        <div className='slide fade-in active' style={{backgroundImage: `url(${img})`}}>
            <div className="slide-content">
                <div className="slide-tittle fade-in active">
                    <h2 dangerouslySetInnerHTML={{ __html: tittle }}/>
                </div>
                <div className="slide-text fade-in active">
                    <p dangerouslySetInnerHTML={{ __html: text }} />
                </div>
                <div className="slide-buttons fade-in active">
                    <Button text={buttonOneProps.text} link={buttonOneProps.link} color={buttonOneProps.color} backgroundColor={buttonOneProps.backgroundColor} borderColor={buttonOneProps.borderColor} target={"_blank"}/>
                    <Button text={buttonTwoProps.text} link={buttonTwoProps.link} color={buttonTwoProps.color} backgroundColor={buttonTwoProps.backgroundColor} borderColor={buttonTwoProps.borderColor} />
                </div>
            </div>
        </div>
    )
}

export default Slide