const Button = ({text, color, link, backgroundColor, borderColor, onClick, target}) => {

    function handleAnchorLink(anchorLink) {
        let element = document.querySelector(anchorLink)
        let menu = document.querySelector('.menu')
        let elementPosition = element.getBoundingClientRect().top + window.scrollY
        if(menu.classList.contains('fixed')) {
            window.scrollTo({
                top: (elementPosition - 44),
                behavior: "smooth"
            })
        } else {
            window.scrollTo({
                top: (elementPosition - 88),
                behavior: "smooth"
            })
        }

    }

    return(
        <>
            {link?.startsWith("#")
            ? <a target={target}><button className='default-button' style={{backgroundColor: backgroundColor, color: color, border: `3px solid ${borderColor}`}} onClick={() => handleAnchorLink(link)}>{text}</button></a>
            : <a href={link} target={target}><button className='default-button' style={{backgroundColor: backgroundColor, color: color, border: `3px solid ${borderColor}`}} onClick={onClick}>{text}</button></a>
            }
        </>
        
    )
}

export default Button