import serviceOneImg from './images/service1.png'
import serviceTwoImg from './images/service2.png'
import serviceThreeImg from './images/service3.png'
import serviceFourImg from './images/service4.png'
import serviceFiveImg from './images/service5.png'
import serviceSixImg from './images/service6.png'
import serviceSevenImg from './images/service7.png'
import serviceEightImg from './images/service8.png'

export const services = [
    {
        name: 'Filipetas / Flyer',
        description: 'Usados para promover um negócio ou loja ou campanha promocional, as filipetas ou flyer geralmente são confeccionadas em papel couche 90g ou 115g nos tamanhos 10x15cm ou 15x21cm podendo ter impressão frente e verso ou apenas na frente.',
        img: serviceOneImg
    },
    {
        name: 'Folders / Portfólios',
        description: 'São usados para promover produtos ou negócios de uma empresa de uma forma mais ampla pois possuem maior espaço para incluir informações. Possuem dobra e geralmente são confeccionados em papel couche de 90g a 300g e podem conter acabamento especial como laminação, verniz ou plastificação.',
        img: serviceTwoImg
    },
    {
        name: 'Cartões de Visita',
        description: 'São excelentes para contactar novos clientes e deixar o seu contato de forma rápida e eficaz. Geralmente são feitos no tamanho 9x5cm em papel couche 300g, podendo ter acabamentos especiais como verniz, laminação ou verniz localizado.',
        img: serviceThreeImg
    },
    {
        name: 'Pastas',
        description: 'As pastas servem para guardar documentos e são uma excelente alternativa para destacar a sua empresa. Geralmente são feitas em papel triplex 300g com acabamento em verniz ou plastificação no tamanho 31x46cm (aberta) e contêm bolso interno ou orelha.',
        img: serviceFourImg
    },
    {
        name: 'Envelopes',
        description: 'Servem para guardar documentos e são uma excelente alternativa para destacar a sua empresa. Podem possuir diversos tamanhos, sendo os mais usados no tamanho para A4, A5 ou 11,4x22,9 (carta) em papel offset 90g.',
        img: serviceFiveImg
    },
    {
        name: 'Papel Timbrado',
        description: 'São usados por empresas para transparecerem maior credibilidade em seus documentos. São feitos em papel offset 75g ou 90g no tamanho 21x29,7cm (A4) com impressão apenas em um dos lados do papel.',
        img: serviceSixImg
    },
    {
        name: 'Blocos de Receituário Médico',
        description: 'São usados por médicos ou hospitais para receitar medicamentos ou exames. Geralmente são no tamanho A4 ou A5, feitos em papel offset 75g ou 90g.',
        img: serviceSevenImg
    },
    {
        name: 'Cardápio',
        description: 'Servem para informar os pratos, porções, bebidas, etc de um restaurante, lanchonete, cafeteria, etc. Geralmente são feitos em papel couche 300g com laminação fosca ou brilhosa no tamanho A4 ou A3 com uma dobra.',
        img: serviceEightImg
    },
    {
        name: 'Blocos de Pedido',
        description: 'São comumente usados em restaurantes ou lojas para anotar os pedidos dos clientes. Geralmente são feitos em papel offset 75g ou 90g nas medidas 10x21cm ou 15x21cm. Podem ser numerados, com mais de uma via e com acabamentos como grampo e picote.'
    },
    {
        name: 'Bloco de Recibo',
        description: 'Servem para anotar o recebimento do cliente. São feitos geralmente na medida 15x21cm em papel offset 75g podendo ser com uma ou duas vias.'
    },
    {
        name: 'Bloco de Anotações',
        description: 'São uma excelente alternativa de brinde para dar para os clientes pois servem para fazer anotações. Podem ter capa personalizada. Geralmente são feitos na medida de 10x15cm ou 15x21cm com 20, 30 ou 50 folhas para anotações.'
    },
    {
        name: 'Revistas',
        description: 'As revistas servem para divulgar notícias ou demais informações. Geralmente são feitas com capa em papel couche 115g e miolo em papel couche 90g no tamanho 21x29,7cm.'
    },
    {
        name: 'Livros',
        description: 'Os livros registram histórias, contos ou poesias. São excelentes formas de eternizar memórias. Geralmente são feitos com capa em papel triplex ou couche 250g ou 300g com acabamento em laminação e miolo em papel offset 75g.'
    },
    {
        name: 'Agendas',
        description: 'As agendas são uma excelente opção de brinde para seus clientes e servem para nos ajudar a organizar horários e rotinas. Geralmente são feitas com capa dura e miolo em papel offset 75g, no tamanho de 15x21cm, presos com duplo anel (Wire-o).'
    },
    {
        name: 'Cadernos',
        description: 'Servem para fazer anotações importantes e podem ser uma excelente opção de brinde para seus clientes. Geralmente são feitas com capa dura e miolo em papel offset 75g presos com duplo anel (Wire-o).'
    },
    {
        name: 'Cadernetas',
        description: 'São excelente opção de brinde. Geralmente são feitas em papel offset 75g no tamanho de 10x15cm ou 15x21cm.'
    },
    {
        name: 'Encartes de Mercado ou Farmácia',
        description: 'Servem para promover preços de produtos e são excelente forma de atrair clientes. Geralmente são feitos em papel couche 80g no tamanho A4 ou A3 com uma dobra.'
    },
    {
        name: 'Tags de Roupa ou Acessórios',
        description: 'São usadas para identificar e precificar os produtos do seu negócio. Geralmente são feitos em papel couche, triplex ou duplex 300g. Podem possuir furo, dobra e acabamentos como laminação brilhosa ou fosca.'
    },
    {
        name: 'Cartazes',
        description: 'São usados para promover eventos ou para informar. Geralmente são feitos em papel couche 115g no tamanho 30x42cm (A3).'
    },
    {
        name: 'Caixas de Papel Cartão',
        description: 'São feitas para embalar um produto a fim de protegê-lo do ambiente externo, além de carregar informações importantes como data de fabricação e validade e informações nutricionais. Geralmente são feitas em papel triplex ou duplex 250g ou 300g.'
    },
    {
        name: 'Bolsas de Loja em Papel',
        description: 'São feitas para embalar produtos para presente e servem para facilitar o cliente na hora de carregar o produto. Podem ter diversos tipos de tamanhos e ser feitas em vários tipos de papeis.'
    },
    {
        name: 'Adesivos',
        description: 'Servem para identificar objetos ou vedar sacolas ou caixas. São feitos em papel adesivo e podem ter corte reto ou arredondado.'
    },
    {
        name: 'Rótulos',
        description: 'Servem para identificar produtos e possuem informações importantes sobre ele, como data de fabricação e validade, tabela nutricional, dentre outros. Geralmente são feitos em papel adesivo com acabamento em laminação brilho.'
    },
    {
        name: 'Apostila',
        description: 'Servem como manuais para cursos destinados ao estudo de um determinado assunto. Geralmente são feitas com capa em papel triplex 300g e miolo em papel offset 75g no tamanho A4 com acabamento em espiral.'
    },
    {
        name: 'Calendário de Mesa ou Calendário de Parede',
        description: 'São excelente opção de brinde para dar aos clientes e servem para informar as datas. Os calendários de mesa geralmente são feitos com a base em papel triplex 300g com ou sem impressão e com 6 ou doze folhinhas com cada mês.'
    },
    {
        name: 'Capa de Carnê',
        description: 'Servem para controle de pagamentos dos clientes e geralmente são feitos em papel couche 150g ou 170g com uma dobra.'
    },
    {
        name: 'Capa de Exame',
        description: 'Servem para encapar exames de uma clínica ou laboratório. Geralmente são feitos em papel couche 170g, no tamanho A4 com uma dobra e com orelhas.'
    },
    {
        name: 'Ficha Clínica',
        description: 'Servem para controle de pacientes. Geralmente são feitas em papel offset 240g 15x21cm.'
    },
    {
        name: 'Certificados',
        description: 'Servem para certificar a conclusão de curso de um aluno. Geralmente são feitos em papel couche 250g no tamanho 15x21cm.'
    },
    {
        name: 'Convites',
        description: 'São feitos para convidar pessoas para um evento. Podem ser feitos em diversos tipos de papeis e com diversos tipos de acabamentos. Geralmente possuem o tamanho A4 com uma dobra.'
    },
    {
        name: 'Jornais / Tabloides',
        description: 'Servem para informar e anunciar empresas. Geralmente são feitos em papel jornal ou papel offset 75g.'
    },
    {
        name: 'Marcador de Página',
        description: 'Servem para auxiliar o leitor a demarcar uma página de um livro durante sua leitura. Geralmente são feitos no tamanho 5x21cm em papel couche 300g com laminação brilhosa ou fosca.'
    },
    {
        name: 'Móbile de Papel',
        description: 'Servem para anunciar produtos ou promoções de uma loja e ficam pendurados no teto do estabelecimento. São feitos em papel cartão acoplado, com 35cm de diâmetro e em formato redondo.'
    },
    {
        name: 'Papel Bandeja',
        description: 'Servem de apoio para os pratos durante as refeições para facilitar na limpeza da mesa. Geralmente são feitos em papel offset 56g ou papel Kraft 80g.'
    },
    {
        name: 'Impressos de Campanha Política',
        description: 'Servem para promover os candidatos para eleições.'
    }
];