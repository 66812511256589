import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faRectangleXmark} from "@fortawesome/free-solid-svg-icons"

import sendFileImg from '../assets/images/send-file-explanation.svg'
import sendFileImgMobile from '../assets/images/send-file-explanation-mobile.svg'
import pdfFile from '../assets/downloads/guia.pdf'

import { useEffect, useState } from "react"

const SendFile = () => {

    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if(window.innerWidth < 800) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    })

    function hideSendFile() {
        let sendFileContainer = document.querySelector('.send-file')
        sendFileContainer.classList.remove('active')
        document.body.classList.remove('disable-scrolling')
    }
    return(
        <div className="send-file">
            <div className="send-file-container">
                <div className="close-button" onClick={hideSendFile}>
                    <FontAwesomeIcon icon={faRectangleXmark} style={{color: "red",}} fontSize={30}/>
                </div>
                <div className="send-file-text">
                    <h2>Como enviar um arquivo para Gráfica Drumond</h2>
                    <p>Entre no site <a href="https://wetransfer.com/" target="_blank">www.wetransfer.com</a> e siga os passos!</p>
                </div>
                <div className="send-file-img">
                    <img src={isMobile === true ? sendFileImgMobile : sendFileImg} alt="send-file-explanation" />
                </div>

                <div className="send-file-text">
                    <p>Pronto! Agora envie-nos uma mensagem pelo <a href="https://api.whatsapp.com/send/?phone=5524974041475&text&type=phone_number&app_absent=0" target="_blank">WhatsApp</a> confirmando seu envio!</p>
                </div>
                <a href={pdfFile} target="_blank" rel="noreferrer">Guia de Pré-Impressão e Acabamentos</a>
            </div>
        </div>
        
    )
}

export default SendFile
