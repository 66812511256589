import { useState } from "react"
import "../assets/styles/components.css"
import { Link } from "react-router-dom"

const NavItens = ({name, url, target, updateMobileMenuState, onClick }) => {

    function handleAnchorLink(anchorLink) {
        let mobileMenu = document.querySelector('.mobile-nav-itens')
        let menu = document.querySelector('.menu')
        let element = document.querySelector(anchorLink)
        let elementPosition = element.getBoundingClientRect().top + window.scrollY

        if(mobileMenu.classList.contains('active')) {
            mobileMenu.classList.remove('active')
            updateMobileMenuState('')
        }

        if(menu.classList.contains('fixed')) {
            window.scrollTo({
                top: (elementPosition - 44),
                behavior: "smooth"
            })
        } else {
            window.scrollTo({
                top: (elementPosition - 88),
                behavior: "smooth"
            })
        }
    }

    function showSendFile() {
        let sendFileContainer = document.querySelector('.send-file')
        sendFileContainer.classList.add('active')
        document.body.classList.add('disable-scrolling')
    }

    return(
        <li key={name}>
            {url.startsWith("#") 
            ? <Link target={target} onClick={() => handleAnchorLink(url)}>{name}</Link>
            : <Link to={url} target={target} rel="noqreferrer" onClick={name === "Enviar Arquivo" ? showSendFile : null}>{name} </Link>
            }
            
        </li>
    )
}

export default NavItens