import "../assets/styles/components.css"
import NavItens from './NavItens.js'

import iconeMenu from '../assets/images/icone-menu.png'
import { useState } from "react"
import SocialIcons from "./SocialIcons"
import SendFile from "./SendFile"

let navLinks = [
    {name: "Início", link: "#inicio"},
    {name: "Sobre Nós", link: "#drumond"},
    {name: "Produtos", link: "#servicos"},
    {name: "Contato", link: "#contato"},
    {name: "Enviar Arquivo", link: ""}
]

const Nav = () => {

    const [mobileMenuState, setMobileMenuState] = useState('')

    const handleMobileMenuState = () => {
        let mobileNav = document.querySelector('.mobile-nav-itens')

        if(mobileMenuState === 'active') {
            mobileNav.style.display = "flex"
            setMobileMenuState('')
        } else {
            setMobileMenuState('active')
        }
    }

    function updateMobileMenuState(state) {
        setMobileMenuState(state)
    }

    return(
        <>
            <div className="mobile-nav">
                <div className="mobile-nav-button" onClick={handleMobileMenuState}>
                    <img src={iconeMenu} alt="mobile-icon" width={30} />
                </div>
                <div className={mobileMenuState === 'active' ? 'mobile-nav-itens active': 'mobile-nav-itens'}>
                    <ul>
                        {navLinks.map(navLink => {
                                return <NavItens key={navLink.name} name={navLink.name} url={navLink.link} target={navLink.target} updateMobileMenuState={updateMobileMenuState} onClick={navLink.onClick}/>
                        })}
                    </ul>
                </div>
            </div>
            <div className="nav">
                <ul>
                    {navLinks.map(navLink => {
                            return <NavItens key={navLink.name} name={navLink.name} url={navLink.link}/>
                    })}
                </ul>
            </div>
            <SocialIcons size={25} colorFacebook={'white'} colorInstagram={'white'}/>
            <SendFile />
        </>
        
    )
}

export default Nav