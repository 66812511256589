import Button from "./Button"

const IconText = ({img, name, description}) => {
    return(
        <div className="icon-text-container">
            <div className="icon-img">
                <img src={img} alt="" />
            </div>
            <div className="icon-texts">
                <h4>{name}</h4>
            </div>
            <div className="icon-button">
            
            </div>
        </div>
    )
}

export default IconText