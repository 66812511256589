import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faRectangleXmark} from "@fortawesome/free-solid-svg-icons"

const AllServices = ({visibility, changeVisibility, services}) => {
    return(
        <div className={visibility === "active" ? "all-services active": "all-services"}>
            <div className="all-services-container">
                
                <div className="all-services-tittle">
                    <h2>Todos os Produtos</h2>
                    <div className="close-button" onClick={() => changeVisibility('')}>
                        <FontAwesomeIcon icon={faRectangleXmark} style={{color: "red",}} fontSize={30}/>
                    </div>
                </div>
        
                <div className="all-services-list">
                    {services.map(service => {
                        return(
                            <div key={service.name} className="service">
                                <div className="service-name">
                                    <p>{service.name}</p>
                                </div>
                                <div className="service-text">
                                    <p>{service.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default AllServices