import Button from "./Button";
import IconText from "./IconText";

import {services} from '../assets/services.js'
import AllServices from "./AllServices";
import { useState } from "react";

const Services = () => {

    const [allServicesVisibility, setAllServicesVisibility] = useState('')

    function showHideAllServices(action) {
        setAllServicesVisibility(action)
        if(action === "active") {
            document.body.classList.add('disable-scrolling')
        } else {
            document.body.classList.remove('disable-scrolling')
        } 
    }

    return(
        <div id="servicos" className="services">
            <div className="wrapper">
                <h2>Produtos</h2>
                <div className="services-icons animation fade-in">
                    {services.slice(0, 8).map((service, index) => {
                        return <IconText key={index} name={service.name} description={service.description} img={service.img}/>
                    })}
                </div>
                <div className="services-button">
                    <Button text="Ver todos produtos" backgroundColor={'#02639b'} color={'white'} onClick={() => showHideAllServices("active")}/>
                </div>
            </div>
            <AllServices visibility={allServicesVisibility} changeVisibility={showHideAllServices} services={services}/>
        </div>
    )
}

export default Services