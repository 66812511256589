import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquareFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

const SocialIcons = ({colorInstagram, colorFacebook, size}) => {
    return(
        <div className="social-icons">
            <a href="https://www.facebook.com/graficadrumond" target="_blank"><FontAwesomeIcon icon={faSquareFacebook} style={{color: colorFacebook,}} fontSize={size} /></a>
            <a href="https://www.instagram.com/graficadrumond/" target="_blank"><FontAwesomeIcon icon={faInstagram} style={{color: colorInstagram,}} fontSize={size}/></a>
        </div>
    )
}

export default SocialIcons