import Slider from "../components/Slider"
import Services from "../components/Services"
import AboutUs from "../components/AboutUs"
import WhatsAppButton from "../components/WhatsAppButton"

import { useEffect } from "react"
import Contact from "../components/Contact"
import Localization from "../components/Localization"



const Home = () => {

    useEffect(() => {
        scrollAnimation()
    }, [])

    const scrollAnimation = () => {
        let elementsWithAnimationClass = document.querySelectorAll('.animation')
        window.addEventListener('scroll', () => {
           
           elementsWithAnimationClass.forEach(element => {
                let elementTop = element.getBoundingClientRect().top
                if(elementTop < 0 + 500) {
                    element.classList.add('active')
                }
           })
        })
    }

    return(
        <>  
            <Slider />
            <AboutUs />
            <Services />
            <Contact />
            <Localization />
            <WhatsAppButton />
        </>
    )
}

export default Home