import whatsAppImg from '../assets/images/whatsapp.png'

const WhatsAppButton = () => {
    return(
        <div className="whatsapp-button">
            <a href="https://api.whatsapp.com/send/?phone=5524974041475&text&type=phone_number&app_absent=0" target='_blank'><img src={whatsAppImg} alt="whatsappimg" /></a>
        </div>
    )
}

export default WhatsAppButton