import ContactForm from "./ContactForm"
import Icons from "./Icons"

import {faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import SocialIcons from "./SocialIcons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

import qrcode1 from '../assets/images/qrcode1.jpg'
import qrcode2 from '../assets/images/qrcode2.jpg'

const Contact = () => {
    return(
        <div id="contato" className="contact">
            <div className="wrapper">
                <div className="contact-details">
                    <div className="contact-details-container animation fade-left">
                        <h3>Contato</h3>
                        <div className="contact-icons">
                            
                            
                            <div className="whatsapp-contact">
                                <Icons link="https://api.whatsapp.com/send/?phone=5524974041475&amp;text&amp;type=phone_number&amp;app_absent=0" icon={faWhatsapp} text="(24) 97404-1475" subText="WhatsApp" size={30} width={30}/> 
                            </div>
                            <Icons link="tel: +552433258024" icon={faPhone} text="(24) 3325-8024" subText="Fixo" size={30} width={30}/>

                            <Icons link="mailto: contato@graficadrumond.com.br" icon={faEnvelope} text="contato@graficadrumond.com.br" subText="Fale conosco" size={30} width={30}/>
                        </div>
                        <h3>Redes Sociais</h3>
                        <div className="contact-social">
                            <SocialIcons  colorFacebook={"#02639b"} colorInstagram={"#02639b"} size={50}/>
                            
                        </div>
                        <h3>QR Codes</h3>
                        <div className="qr-codess">
                            <img src={qrcode1} alt="qrcode" width={100}/>
                            <img src={qrcode2} alt="qrcode" width={100}/>
                        </div>
                    </div>
                </div>
                <div className="contact-form animation fade-right">
                    <ContactForm/>
                </div>
            </div>
        </div>
    )
}

export default Contact