import mapPin from '../assets/images/map-pin.png'

const Localization = () => {
    return(
        <div className="maps">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3098.891253634845!2d-44.18645062478432!3d-22.53329793725086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9e9bee0a1826ff%3A0x53c1cb8594e662e1!2sGr%C3%A1fica%20Drumond!5e0!3m2!1spt-BR!2sbr!4v1691677324594!5m2!1spt-BR!2sbr" width="100%" height="100%" style={{border: "0"}} ></iframe>
        </div>
    )
}

export default Localization