const ContactForm = () => {
    return(
        <>
            <form>
                <h3>Entre em contato</h3>
                <div className="contact-name-input">
                    <input type="text" name="contact_name_input" id="contact_name_input" placeholder="Nome" required/>
                </div>
                <div className="contact-email-input">
                    <input type="text" name="contact_email_input" id="contact_email_input" placeholder="Email" required/>
                </div>
                <div className="contact-tel-input">
                    <input type="text" name="contact_tel_input" id="contact_tel_input" placeholder="Telefone"/>
                </div>
                <div className="contact-textarea-input">
                    <textarea name="contact-form-textarea" id="contact-form-textarea" cols="30" rows="7" placeholder="Digite sua mensagem aqui."></textarea>
                </div>
                <input className="contact-submit" type="submit" value="Enviar" />
            </form>
        </>
    )
}

export default ContactForm